import { default as useAuthStore } from '@src/store/auth';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthStore((state) => state);
  if (auth.token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default PublicRoute;
