// import { useToaster } from '@src/hooks/use-toaster';
import { default as useAuthStore } from '@src/store/auth';
// import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthStore((state) => state);
  if (!auth.token) {
    return <Navigate to="/auth/login" replace />;
  }
  return children;
};

export default ProtectedRoute;
