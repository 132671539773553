// import * as pdfjs from 'pdfjs-dist';
// import pdfWorkerURL from 'pdfjs-dist/build/pdf.worker.min?url';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import App from './app';
import './i18n';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   pdfWorkerURL,
//   import.meta.url
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `${
  import.meta.env.BASE_URL
}pdf.worker.mjs`;

// console.log(`${import.meta.env.BASE_URL}pdf.worker.mjs`);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
