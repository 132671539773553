/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface Store {
  id?: string;
  email?: string;
  role?: string;
  settings?: { [key: string]: any };
  token?: string;
  expired?: boolean;
  auth: boolean;
  set: (
    id: string,
    email: string,
    role: string,
    settings: { [key: string]: any },
    token: string
  ) => void;
  clear: () => void;
  expire: (expired?: boolean) => void;
}

const useStore = create<Store>()(
  persist(
    (set, get) => ({
      auth: false,
      set: (
        id: string,
        email: string,
        role: string,
        settings: { [key: string]: any },
        token: string
      ) => {
        localStorage.setItem('token', token);
        set(() => ({
          id: id,
          email: email,
          role: role,
          settings: settings,
          token: token,
          auth: true,
          expired: false,
        }));
      },
      clear: () => {
        const token = localStorage.getItem('token');
        if (token !== undefined || token !== null || token !== '') {
          localStorage.setItem('token', '');
        }
        if (get().auth === true && get().token !== undefined) {
          set(() => ({
            id: undefined,
            email: undefined,
            token: undefined,
            auth: false,
            expired: undefined,
          }));
        }
      },
      expire: (expired = true) => {
        set(() => ({
          expired: expired,
        }));
      },
    }),
    {
      name: 'auth-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
